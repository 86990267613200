import React, { useState } from 'react'
import { Button, TextField, Grid, Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useCreateSupplierMutation } from '../../generated/graphql'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
  textField: {
    width: '100%',
  },
})

const SupplierForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [createSupplier] = useCreateSupplierMutation()
  const classes = useStyles()

  const [name, setName] = useState('')
  const [iban, setIban] = useState('')
  const [ico, setIco] = useState(0)
  const [dic, setDic] = useState(0)

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const input = {
      name,
      iban,
      ico,
      dic,
    }

    const variables = {
      input,
    }

    try {
      const data = await createSupplier({ variables })

      enqueueSnackbar('Dodávateľ úspešne vytvorený!', { variant: 'success' })

      if (data) {
        window.location.href = '/dash/suppliers'
      }
    } catch (err) {
      enqueueSnackbar('Vytvorenie dodávateľa zlyhalo.', { variant: 'error' })
      console.error(err)
    }
  }

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} direction='column'>
          <Grid item>
            <TextField
              label='Meno'
              value={name}
              onChange={(event) => setName(event.target.value)}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <TextField
              label='IBAN'
              value={iban}
              onChange={(event) => setIban(event.target.value)}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <TextField
              label='IČO'
              type='number'
              value={ico || ''}
              onChange={(event) => setIco(parseFloat(event.target.value))}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <TextField
              label='DIČ'
              type='number'
              value={dic || ''}
              onChange={(event) => setDic(parseFloat(event.target.value))}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <Button type='submit' variant='contained'>
              Pridať
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default SupplierForm
