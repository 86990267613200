import React from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { Navbar } from './components/Navbar'
import Home from './pages/Home'
import { Container } from '@mui/material'
import Order from './pages/Order'
import Invoice from './pages/Invoice'
import Contract from './pages/Contract'
import Dash from './pages/Dash/Index'
import OrdersDash from './pages/Dash/Orders'
import ContractDash from './pages/Dash/Contracts'
import InvoiceDash from './pages/Dash/Invoice'
import SupplierDash from './pages/Dash/Supplier'

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Container maxWidth='xl' className={'body-container'}>
        <Switch>
          <Route path='/dash/orders' component={OrdersDash} />
          <Route path='/dash/contracts' component={ContractDash} />
          <Route path='/dash/invoices' component={InvoiceDash} />
          <Route path='/dash/suppliers' component={SupplierDash} />
          <Route path='/dash' component={Dash} />
          <Route path='/orders' component={Order} />
          <Route path='/contracts' component={Contract} />
          <Route path='/invoices' component={Invoice} />
          <Route path='/' component={Home} />
        </Switch>
      </Container>
    </BrowserRouter>
  )
}

export default App
