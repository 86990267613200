import SupplierForm from '../../components/Supplier/SupplierForm'
import SupplierTable from '../../components/Table/SupplierTable'
import { Grid } from '@mui/material'

const SupplierDash = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid md={4} item>
          <SupplierForm />
        </Grid>
        <Grid md={8} item>
          <SupplierTable />
        </Grid>
      </Grid>
    </div>
  )
}

export default SupplierDash
