import InvoiceForm from '../../components/Invoice/InvoiceForm'
const InvoiceDash = () => {
  return (
    <div>
      <InvoiceForm />
    </div>
  )
}

export default InvoiceDash
