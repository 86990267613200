import { Supplier as SupplierInterface, useGetSuppliersQuery } from '../../generated/graphql'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'

type TableData = SupplierInterface & { index: number }

const SupplierTable: React.FC = () => {
  const { data: supplier, loading } = useGetSuppliersQuery()
  const [data, setData] = useState<TableData[]>([])

  useEffect(() => {
    if (supplier) {
      setData(
        supplier.suppliers.map((order, index) => ({ ...order, index: index + 1 })) as TableData[],
      )
    }
  }, [supplier])

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 20,
      valueGetter: (params: any) => {
        return params.row.index
      },
    },
    { field: 'name', headerName: 'Názov dodavatela', width: 300 },
    { field: 'ico', headerName: 'IČO', width: 100 },
    { field: 'dic', headerName: 'DIČ', width: 100 },
    { field: 'iban', headerName: 'IBAN', width: 200 },
  ]

  return <DataGrid rows={data} columns={columns} loading={loading} />
}

export default SupplierTable
