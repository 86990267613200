import { Link } from 'react-router-dom'
import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-around', // Rozloženie boxíkov rovnomerne po celej šírke
    padding: '20px', // Trochu priestoru okolo boxíkov
  },
  card: {
    width: '30%', // Každý boxík bude zaujímať približne tretinu šírky
    height: '100px', // Výška boxíka
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#556cd6',
    color: '#fff',
    textDecoration: 'none',
  },
})

interface ButtonBoxProps {
  title: string
  path: string
}

const ButtonBox = ({ title, path }: ButtonBoxProps) => {
  const classes = useStyles()

  return (
    <Link to={path} className={classes.card}>
      <Card>
        <CardContent>
          <Typography variant='h5' component='div'>
            {title}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  )
}

export default ButtonBox
