import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { useLoginMutation, UserInfo, useGetUserInfoQuery } from '../generated/graphql'
import { useSnackbar } from 'notistack'
import Cookies from 'js-cookie'

interface AuthContextType {
  user: UserInfo | null
  login: (username: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

const AuthContext = createContext<AuthContextType | null>(null)

interface AuthProviderProps {
  children: ReactNode
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<UserInfo | null>(null)
  const { data, loading, error } = useGetUserInfoQuery() // getUserInfo GraphQL query
  const [loginMutation] = useLoginMutation()
  const { enqueueSnackbar } = useSnackbar()

  async function login(username: string, password: string): Promise<void> {
    try {
      const response = await loginMutation({
        variables: {
          input: {
            username,
            password,
          },
        },
      })
      if (response.data?.login) {
        setUser(response.data.login)
        if (response.data.login.accessToken != null) {
          Cookies.set('authToken', response.data.login.accessToken, { expires: 7 })
        }
        enqueueSnackbar('Successfully logged in!', { variant: 'success' }) // zobrazíme notifikáciu
      }
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Failed to log in', { variant: 'error' }) // zobrazíme chybovú notifikáciu
    }
  }

  async function logout(): Promise<void> {
    try {
      setUser(null)
      Cookies.remove('authToken')
      enqueueSnackbar('Successfully logged out!', { variant: 'success' }) // zobrazíme notifikáciu
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Failed to log out', { variant: 'error' }) // zobrazíme chybovú notifikáciu
    }
  }

  useEffect(() => {
    if (!loading && data) {
      setUser(data.userInfo)
    }
  }, [loading, data])

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
