import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import { Contract as ContractInterface, useGetContractsQuery } from '../generated/graphql'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import InvoiceTable from '../components/Table/InvoiceTable'
import theme from '../libs/theme'

type TableData = ContractInterface & { index: number }

const useStyles = makeStyles(() => ({
  searchField: {
    borderRadius: '50px !important',
    margin: '20px 0',
  },
}))

const Contract = () => {
  const classes = useStyles()
  const [data, setData] = useState<TableData[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRow, setSelectedRow] = useState<TableData>({} as TableData)
  const [open, setOpen] = useState(false)
  const { data: contract, loading } = useGetContractsQuery()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (contract) {
      setData(
        contract.contracts.map((order, index) => ({ ...order, index: index + 1 })) as TableData[],
      )
    }
  }, [contract])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleRowClick = (param: any) => {
    setSelectedRow(param)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 20,
      valueGetter: (params: any) => {
        return params.row.index
      },
    },
    { field: 'subject', headerName: 'Predmet', width: 650 },
    { field: 'price', headerName: 'Cena' },
    {
      field: 'dateIssue',
      headerName: 'Dátum vystavenia',
      width: 100,
      valueGetter: (params: any) => {
        return new Date(params.row.dateIssue).toLocaleDateString('sk-SK')
      },
    },
    {
      field: 'deliveryDate',
      headerName: 'Dátum dodania',
      width: 150,
      valueGetter: (params: any) => {
        return new Date(params.row.dateIssue).toLocaleDateString('sk-SK')
      },
    },
    {
      field: 'priceWithDPH',
      headerName: 'DPH',
      valueGetter: (params: any) => {
        if (params.row.priceWithDPH) {
          return 'Platca'
        } else {
          return 'Neplatca'
        }
      },
    },
    {
      field: 'supplier',
      headerName: 'Názov dodávateľa',
      width: 150,
      valueGetter: (params: any) => {
        return params.row.supplier.name
      },
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: (params: any) => (
        <Button className={classes.searchField} onClick={() => handleRowClick(params.row)}>
          Detail
        </Button>
      ),
    },
  ]

  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )

  return (
    <>
      <Typography variant='h5' component='div'>
        Zmluvy
      </Typography>
      <div>
        <TextField
          id='outlined-basic'
          label={'Hladat'}
          value={searchTerm}
          onChange={handleSearchChange}
          className={classes.searchField}
        />
      </div>
      <DataGrid
        rows={filteredData}
        columns={columns}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullScreen={fullScreen}
      >
        <DialogTitle id='form-dialog-title'>Detail Zmluvy</DialogTitle>
        <DialogContent>
          <Typography variant='h6' component='div'>
            Zmluvy
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {selectedRow.invoices && (
                <InvoiceTable invoices={selectedRow.invoices} loading={loading} />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Contract
