import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type BooleanNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Contract = {
  __typename?: 'Contract';
  createdAt: Scalars['DateTime']['output'];
  dateIssue?: Maybe<Scalars['DateTime']['output']>;
  deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoices: Array<Invoice>;
  numberOfContract?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceWithDph?: Maybe<Scalars['Boolean']['output']>;
  publicDate?: Maybe<Scalars['DateTime']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  supplier?: Maybe<Supplier>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};


export type ContractInvoicesArgs = {
  orderBy?: InputMaybe<Array<InvoiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};

export type ContractCreateInput = {
  dateIssue?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutContractsInput>;
  numberOfContract?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  priceWithDph?: InputMaybe<Scalars['Boolean']['input']>;
  publicDate?: InputMaybe<Scalars['DateTime']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  supplier?: InputMaybe<SupplierWhereUniqueInput>;
  user?: InputMaybe<UserWhereUniqueInput>;
};

export type ContractCreateNestedManyWithoutSuppliersInput = {
  connect?: InputMaybe<Array<ContractWhereUniqueInput>>;
};

export type ContractCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<ContractWhereUniqueInput>>;
};

export type ContractListRelationFilter = {
  every?: InputMaybe<ContractWhereInput>;
  none?: InputMaybe<ContractWhereInput>;
  some?: InputMaybe<ContractWhereInput>;
};

export type ContractOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  dateIssue?: InputMaybe<SortOrder>;
  deliveryDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  numberOfContract?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceWithDph?: InputMaybe<SortOrder>;
  publicDate?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  supplierId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ContractUpdateInput = {
  dateIssue?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutContractsInput>;
  numberOfContract?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  priceWithDph?: InputMaybe<Scalars['Boolean']['input']>;
  publicDate?: InputMaybe<Scalars['DateTime']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  supplier?: InputMaybe<SupplierWhereUniqueInput>;
  user?: InputMaybe<UserWhereUniqueInput>;
};

export type ContractUpdateManyWithoutSuppliersInput = {
  connect?: InputMaybe<Array<ContractWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<ContractWhereUniqueInput>>;
  set?: InputMaybe<Array<ContractWhereUniqueInput>>;
};

export type ContractUpdateManyWithoutUsersInput = {
  connect?: InputMaybe<Array<ContractWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<ContractWhereUniqueInput>>;
  set?: InputMaybe<Array<ContractWhereUniqueInput>>;
};

export type ContractWhereInput = {
  dateIssue?: InputMaybe<DateTimeNullableFilter>;
  deliveryDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  numberOfContract?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  priceWithDph?: InputMaybe<BooleanNullableFilter>;
  publicDate?: InputMaybe<DateTimeNullableFilter>;
  subject?: InputMaybe<StringNullableFilter>;
  supplier?: InputMaybe<SupplierWhereUniqueInput>;
  user?: InputMaybe<UserWhereUniqueInput>;
};

export type ContractWhereUniqueInput = {
  id: Scalars['String']['input'];
};

export type Credentials = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  contract?: Maybe<Contract>;
  createdAt: Scalars['DateTime']['output'];
  dodavatelska?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  numberOfInvoice?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  publicDate?: Maybe<Scalars['DateTime']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceCreateInput = {
  contract?: InputMaybe<ContractWhereUniqueInput>;
  dodavatelska?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfInvoice?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<OrderWhereUniqueInput>;
  publicDate?: InputMaybe<Scalars['DateTime']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceCreateNestedManyWithoutContractsInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceCreateNestedManyWithoutOrdersInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceListRelationFilter = {
  every?: InputMaybe<InvoiceWhereInput>;
  none?: InputMaybe<InvoiceWhereInput>;
  some?: InputMaybe<InvoiceWhereInput>;
};

export type InvoiceOrderByInput = {
  contractId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dodavatelska?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  numberOfInvoice?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  publicDate?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InvoiceUpdateInput = {
  contract?: InputMaybe<ContractWhereUniqueInput>;
  dodavatelska?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfInvoice?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<OrderWhereUniqueInput>;
  publicDate?: InputMaybe<Scalars['DateTime']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceUpdateManyWithoutContractsInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  set?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceUpdateManyWithoutOrdersInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  set?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
};

export type InvoiceWhereInput = {
  contract?: InputMaybe<ContractWhereUniqueInput>;
  dodavatelska?: InputMaybe<BooleanNullableFilter>;
  id?: InputMaybe<StringFilter>;
  numberOfInvoice?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<OrderWhereUniqueInput>;
  publicDate?: InputMaybe<DateTimeNullableFilter>;
  subject?: InputMaybe<StringNullableFilter>;
};

export type InvoiceWhereUniqueInput = {
  id: Scalars['String']['input'];
};

export type MetaQueryPayload = {
  __typename?: 'MetaQueryPayload';
  count: Scalars['Float']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createContract: Contract;
  createInvoice: Invoice;
  createOrder: Order;
  createSupplier: Supplier;
  createUser: User;
  deleteContract: Contract;
  deleteInvoice: Invoice;
  deleteOrder: Order;
  deleteSupplier: Supplier;
  deleteUser: User;
  login: UserInfo;
  updateContract: Contract;
  updateInvoice: Invoice;
  updateOrder: Order;
  updateSupplier: Supplier;
  updateUser: User;
};


export type MutationCreateContractArgs = {
  data: ContractCreateInput;
};


export type MutationCreateInvoiceArgs = {
  data: InvoiceCreateInput;
};


export type MutationCreateOrderArgs = {
  data: OrderCreateInput;
};


export type MutationCreateSupplierArgs = {
  data: SupplierCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteContractArgs = {
  where: ContractWhereUniqueInput;
};


export type MutationDeleteInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type MutationDeleteOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type MutationDeleteSupplierArgs = {
  where: SupplierWhereUniqueInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationLoginArgs = {
  credentials: Credentials;
};


export type MutationUpdateContractArgs = {
  data: ContractUpdateInput;
  where: ContractWhereUniqueInput;
};


export type MutationUpdateInvoiceArgs = {
  data: InvoiceUpdateInput;
  where: InvoiceWhereUniqueInput;
};


export type MutationUpdateOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdateSupplierArgs = {
  data: SupplierUpdateInput;
  where: SupplierWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type Order = {
  __typename?: 'Order';
  createdAt: Scalars['DateTime']['output'];
  dateIssue?: Maybe<Scalars['DateTime']['output']>;
  deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoices: Array<Invoice>;
  numberOfOrder?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceWithDPH?: Maybe<Scalars['Boolean']['output']>;
  publicDate?: Maybe<Scalars['DateTime']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  supplier?: Maybe<Supplier>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};


export type OrderInvoicesArgs = {
  orderBy?: InputMaybe<Array<InvoiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};

export type OrderCreateInput = {
  dateIssue?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutOrdersInput>;
  numberOfOrder?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  priceWithDPH?: InputMaybe<Scalars['Boolean']['input']>;
  publicDate?: InputMaybe<Scalars['DateTime']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  supplier?: InputMaybe<SupplierWhereUniqueInput>;
  user?: InputMaybe<UserWhereUniqueInput>;
};

export type OrderCreateNestedManyWithoutSuppliersInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
};

export type OrderCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
};

export type OrderListRelationFilter = {
  every?: InputMaybe<OrderWhereInput>;
  none?: InputMaybe<OrderWhereInput>;
  some?: InputMaybe<OrderWhereInput>;
};

export type OrderOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  dateIssue?: InputMaybe<SortOrder>;
  deliveryDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  numberOfOrder?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceWithDPH?: InputMaybe<SortOrder>;
  publicDate?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  supplierId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type OrderUpdateInput = {
  dateIssue?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutOrdersInput>;
  numberOfOrder?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  priceWithDPH?: InputMaybe<Scalars['Boolean']['input']>;
  publicDate?: InputMaybe<Scalars['DateTime']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  supplier?: InputMaybe<SupplierWhereUniqueInput>;
  user?: InputMaybe<UserWhereUniqueInput>;
};

export type OrderUpdateManyWithoutSuppliersInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
};

export type OrderUpdateManyWithoutUsersInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
};

export type OrderWhereInput = {
  dateIssue?: InputMaybe<DateTimeNullableFilter>;
  deliveryDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  numberOfOrder?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  priceWithDPH?: InputMaybe<BooleanNullableFilter>;
  publicDate?: InputMaybe<DateTimeNullableFilter>;
  subject?: InputMaybe<StringNullableFilter>;
  supplier?: InputMaybe<SupplierWhereUniqueInput>;
  user?: InputMaybe<UserWhereUniqueInput>;
};

export type OrderWhereUniqueInput = {
  id: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  _contractsMeta: MetaQueryPayload;
  _invoicesMeta: MetaQueryPayload;
  _ordersMeta: MetaQueryPayload;
  _suppliersMeta: MetaQueryPayload;
  _usersMeta: MetaQueryPayload;
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  invoice?: Maybe<Invoice>;
  invoices: Array<Invoice>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  supplier?: Maybe<Supplier>;
  suppliers: Array<Supplier>;
  user?: Maybe<User>;
  userInfo: UserInfo;
  users: Array<User>;
};


export type Query_ContractsMetaArgs = {
  where?: InputMaybe<ContractWhereInput>;
};


export type Query_InvoicesMetaArgs = {
  where?: InputMaybe<InvoiceWhereInput>;
};


export type Query_OrdersMetaArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type Query_SuppliersMetaArgs = {
  where?: InputMaybe<SupplierWhereInput>;
};


export type Query_UsersMetaArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type QueryContractArgs = {
  where: ContractWhereUniqueInput;
};


export type QueryContractsArgs = {
  orderBy?: InputMaybe<Array<ContractOrderByInput>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  where?: InputMaybe<ContractWhereInput>;
};


export type QueryInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryInvoicesArgs = {
  orderBy?: InputMaybe<Array<InvoiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryOrdersArgs = {
  orderBy?: InputMaybe<Array<OrderOrderByInput>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QuerySupplierArgs = {
  where: SupplierWhereUniqueInput;
};


export type QuerySuppliersArgs = {
  orderBy?: InputMaybe<Array<SupplierOrderByInput>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  where?: InputMaybe<SupplierWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'Default',
  Insensitive = 'Insensitive'
}

export enum SortOrder {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  contracts: Array<Contract>;
  createdAt: Scalars['DateTime']['output'];
  dic?: Maybe<Scalars['Float']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  ico?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  invoices: Array<Order>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type SupplierContractsArgs = {
  orderBy?: InputMaybe<Array<ContractOrderByInput>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  where?: InputMaybe<ContractWhereInput>;
};


export type SupplierInvoicesArgs = {
  orderBy?: InputMaybe<Array<OrderOrderByInput>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};

export type SupplierCreateInput = {
  contracts?: InputMaybe<ContractCreateNestedManyWithoutSuppliersInput>;
  dic?: InputMaybe<Scalars['Float']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  ico?: InputMaybe<Scalars['Float']['input']>;
  invoices?: InputMaybe<OrderCreateNestedManyWithoutSuppliersInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SupplierOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  dic?: InputMaybe<SortOrder>;
  iban?: InputMaybe<SortOrder>;
  ico?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SupplierUpdateInput = {
  contracts?: InputMaybe<ContractUpdateManyWithoutSuppliersInput>;
  dic?: InputMaybe<Scalars['Float']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  ico?: InputMaybe<Scalars['Float']['input']>;
  invoices?: InputMaybe<OrderUpdateManyWithoutSuppliersInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SupplierWhereInput = {
  contracts?: InputMaybe<ContractListRelationFilter>;
  dic?: InputMaybe<IntNullableFilter>;
  iban?: InputMaybe<StringNullableFilter>;
  ico?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invoices?: InputMaybe<OrderListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
};

export type SupplierWhereUniqueInput = {
  id: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  contracts: Array<Contract>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoices: Array<Order>;
  lastName?: Maybe<Scalars['String']['output']>;
  roles: Scalars['JSON']['output'];
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};


export type UserContractsArgs = {
  orderBy?: InputMaybe<Array<ContractOrderByInput>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  where?: InputMaybe<ContractWhereInput>;
};


export type UserInvoicesArgs = {
  orderBy?: InputMaybe<Array<OrderOrderByInput>>;
  skip?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};

export type UserCreateInput = {
  contracts?: InputMaybe<ContractCreateNestedManyWithoutUsersInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  invoices?: InputMaybe<OrderCreateNestedManyWithoutUsersInput>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  roles: Scalars['JSON']['input'];
  username: Scalars['String']['input'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  accessToken?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type UserOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  roles?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserUpdateInput = {
  contracts?: InputMaybe<ContractUpdateManyWithoutUsersInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  invoices?: InputMaybe<OrderUpdateManyWithoutUsersInput>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Scalars['JSON']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserWhereInput = {
  contracts?: InputMaybe<ContractListRelationFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invoices?: InputMaybe<OrderListRelationFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  username?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  id: Scalars['String']['input'];
};

export type CreateContractMutationVariables = Exact<{
  input: ContractCreateInput;
}>;


export type CreateContractMutation = { __typename?: 'Mutation', createContract: { __typename?: 'Contract', id: string, dateIssue?: any | null, deliveryDate?: any | null, price?: number | null, priceWithDph?: boolean | null, subject?: string | null, invoices: Array<{ __typename?: 'Invoice', id: string }>, supplier?: { __typename?: 'Supplier', id: string } | null, user?: { __typename?: 'User', id: string } | null } };

export type CreateInvoiceMutationVariables = Exact<{
  input: InvoiceCreateInput;
}>;


export type CreateInvoiceMutation = { __typename?: 'Mutation', createInvoice: { __typename?: 'Invoice', id: string, subject?: string | null, numberOfInvoice?: string | null, publicDate?: any | null, contract?: { __typename?: 'Contract', id: string } | null, order?: { __typename?: 'Order', id: string } | null } };

export type LoginMutationVariables = Exact<{
  input: Credentials;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'UserInfo', accessToken?: string | null, id: string, roles: Array<string>, username: string } };

export type CreateOrderMutationVariables = Exact<{
  input: OrderCreateInput;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'Order', id: string, dateIssue?: any | null, deliveryDate?: any | null, price?: number | null, priceWithDPH?: boolean | null, subject?: string | null, invoices: Array<{ __typename?: 'Invoice', id: string }>, supplier?: { __typename?: 'Supplier', id: string } | null, user?: { __typename?: 'User', id: string } | null } };

export type CreateSupplierMutationVariables = Exact<{
  input: SupplierCreateInput;
}>;


export type CreateSupplierMutation = { __typename?: 'Mutation', createSupplier: { __typename?: 'Supplier', id: string, name?: string | null, ico?: number | null, dic?: number | null, iban?: string | null } };

export type GetContractsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContractsQuery = { __typename?: 'Query', contracts: Array<{ __typename?: 'Contract', id: string, dateIssue?: any | null, deliveryDate?: any | null, price?: number | null, priceWithDph?: boolean | null, publicDate?: any | null, subject?: string | null, numberOfContract?: string | null, supplier?: { __typename?: 'Supplier', name?: string | null, ico?: number | null, dic?: number | null } | null, user?: { __typename?: 'User', username: string, email?: string | null } | null }> };

export type GetInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvoicesQuery = { __typename?: 'Query', invoices: Array<{ __typename?: 'Invoice', id: string, publicDate?: any | null, subject?: string | null, numberOfInvoice?: string | null, order?: { __typename?: 'Order', id: string, dateIssue?: any | null, deliveryDate?: any | null, price?: number | null, priceWithDPH?: boolean | null, publicDate?: any | null, subject?: string | null } | null, contract?: { __typename?: 'Contract', id: string, dateIssue?: any | null, deliveryDate?: any | null, price?: number | null, priceWithDph?: boolean | null, publicDate?: any | null, subject?: string | null } | null }> };

export type GetOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', id: string, dateIssue?: any | null, deliveryDate?: any | null, price?: number | null, priceWithDPH?: boolean | null, subject?: string | null, numberOfOrder?: string | null, invoices: Array<{ __typename?: 'Invoice', id: string, subject?: string | null, publicDate?: any | null, numberOfInvoice?: string | null }>, supplier?: { __typename?: 'Supplier', id: string, name?: string | null, ico?: number | null } | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null }> };

export type GetSuppliersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSuppliersQuery = { __typename?: 'Query', suppliers: Array<{ __typename?: 'Supplier', id: string, name?: string | null, ico?: number | null, dic?: number | null, iban?: string | null, createdAt: any, updatedAt: any }> };

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInfoQuery = { __typename?: 'Query', userInfo: { __typename?: 'UserInfo', id: string, accessToken?: string | null, roles: Array<string>, username: string } };


export const CreateContractDocument = gql`
    mutation createContract($input: ContractCreateInput!) {
  createContract(data: $input) {
    id
    dateIssue
    deliveryDate
    price
    priceWithDph
    subject
    invoices {
      id
    }
    supplier {
      id
    }
    user {
      id
    }
  }
}
    `;
export type CreateContractMutationFn = Apollo.MutationFunction<CreateContractMutation, CreateContractMutationVariables>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractMutation(baseOptions?: Apollo.MutationHookOptions<CreateContractMutation, CreateContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContractMutation, CreateContractMutationVariables>(CreateContractDocument, options);
      }
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = Apollo.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = Apollo.BaseMutationOptions<CreateContractMutation, CreateContractMutationVariables>;
export const CreateInvoiceDocument = gql`
    mutation createInvoice($input: InvoiceCreateInput!) {
  createInvoice(data: $input) {
    id
    subject
    contract {
      id
    }
    numberOfInvoice
    order {
      id
    }
    publicDate
  }
}
    `;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: Credentials!) {
  login(credentials: $input) {
    accessToken
    id
    roles
    username
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CreateOrderDocument = gql`
    mutation createOrder($input: OrderCreateInput!) {
  createOrder(data: $input) {
    id
    dateIssue
    deliveryDate
    price
    priceWithDPH
    subject
    invoices {
      id
    }
    supplier {
      id
    }
    user {
      id
    }
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CreateSupplierDocument = gql`
    mutation createSupplier($input: SupplierCreateInput!) {
  createSupplier(data: $input) {
    id
    name
    ico
    dic
    iban
  }
}
    `;
export type CreateSupplierMutationFn = Apollo.MutationFunction<CreateSupplierMutation, CreateSupplierMutationVariables>;

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupplierMutation, CreateSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupplierMutation, CreateSupplierMutationVariables>(CreateSupplierDocument, options);
      }
export type CreateSupplierMutationHookResult = ReturnType<typeof useCreateSupplierMutation>;
export type CreateSupplierMutationResult = Apollo.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = Apollo.BaseMutationOptions<CreateSupplierMutation, CreateSupplierMutationVariables>;
export const GetContractsDocument = gql`
    query GetContracts {
  contracts {
    id
    dateIssue
    deliveryDate
    price
    priceWithDph
    publicDate
    subject
    supplier {
      name
      ico
      dic
    }
    user {
      username
      email
    }
    numberOfContract
  }
}
    `;

/**
 * __useGetContractsQuery__
 *
 * To run a query within a React component, call `useGetContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContractsQuery(baseOptions?: Apollo.QueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, options);
      }
export function useGetContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, options);
        }
export type GetContractsQueryHookResult = ReturnType<typeof useGetContractsQuery>;
export type GetContractsLazyQueryHookResult = ReturnType<typeof useGetContractsLazyQuery>;
export type GetContractsQueryResult = Apollo.QueryResult<GetContractsQuery, GetContractsQueryVariables>;
export const GetInvoicesDocument = gql`
    query GetInvoices {
  invoices {
    id
    publicDate
    subject
    numberOfInvoice
    order {
      id
      dateIssue
      deliveryDate
      price
      priceWithDPH
      publicDate
      subject
    }
    contract {
      id
      dateIssue
      deliveryDate
      price
      priceWithDph
      publicDate
      subject
    }
  }
}
    `;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
      }
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const GetOrdersDocument = gql`
    query GetOrders {
  orders {
    id
    dateIssue
    deliveryDate
    invoices {
      id
      subject
      publicDate
      numberOfInvoice
    }
    price
    priceWithDPH
    subject
    supplier {
      id
      name
      ico
    }
    user {
      firstName
      lastName
    }
    numberOfOrder
  }
}
    `;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetSuppliersDocument = gql`
    query GetSuppliers {
  suppliers {
    id
    name
    ico
    dic
    iban
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetSuppliersQuery__
 *
 * To run a query within a React component, call `useGetSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<GetSuppliersQuery, GetSuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(GetSuppliersDocument, options);
      }
export function useGetSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSuppliersQuery, GetSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(GetSuppliersDocument, options);
        }
export type GetSuppliersQueryHookResult = ReturnType<typeof useGetSuppliersQuery>;
export type GetSuppliersLazyQueryHookResult = ReturnType<typeof useGetSuppliersLazyQuery>;
export type GetSuppliersQueryResult = Apollo.QueryResult<GetSuppliersQuery, GetSuppliersQueryVariables>;
export const GetUserInfoDocument = gql`
    query GetUserInfo {
  userInfo {
    id
    accessToken
    roles
    username
  }
}
    `;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
      }
export function useGetUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
        }
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;