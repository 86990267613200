import React, { ReactNode } from 'react'
import { makeStyles } from '@mui/styles'
import ButtonBox from '../components/ButtonBox'
import { useAuth } from '../context/AuthContext'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '20px',
  },
  card: {
    width: '30%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#556cd6',
    color: '#fff',
    textDecoration: 'none',
  },
})

type AppProps = {
  children?: ReactNode // definovanie children ako voliteľný typ ReactNode
}

const App: React.FC<AppProps> = ({ children }) => {
  const classes = useStyles()
  const { user } = useAuth()

  return (
    <>
      {user ? (
        <div className={classes.container}>
          <ButtonBox title='Objednávky' path='/orders' />
          <ButtonBox title='Faktúry' path='/invoices' />
          <ButtonBox title='Zmluvy' path='/contracts' />
        </div>
      ) : (
        <div className={classes.container}>
          <ButtonBox title='Objednávky' path='/orders' />
          <ButtonBox title='Faktúry' path='/invoices' />
          <ButtonBox title='Zmluvy' path='/contracts' />
        </div>
      )}
      {children}
    </>
  )
}

export default App
