import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'
import { skSK } from '@mui/x-data-grid'

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#556cd6',
      },
      secondary: {
        main: '#19857b',
      },
      error: {
        main: red.A400,
      },
    },
  },
  skSK,
)

export default theme
