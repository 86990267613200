import { Invoice } from '../../generated/graphql'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React from 'react'

interface InvoiceTableProps {
  invoices: Invoice[]
  loading: boolean
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({ invoices, loading }) => {
  const filteredData = invoices.map((invoice, index) => {
    return {
      id: index + 1,
      numberOfInvoice: invoice.numberOfInvoice,
      subject: invoice.subject,
      publicDate: invoice.publicDate,
    }
  })

  const columns = [
    { field: 'id', headerName: 'ID', width: 15 },
    { field: 'numberOfInvoice', headerName: 'č. FA', width: 20 },
    { field: 'subject', headerName: 'Predmet', width: 300 },
    {
      field: 'publicDate',
      headerName: 'Datum zverejnenia',
      width: 200,
      valueGetter: (params: any) => {
        return new Date(params.row.publicDate).toLocaleString('sk-SK')
      },
    },
  ]

  return <DataGrid rows={filteredData} columns={columns} loading={loading} />
}

export default InvoiceTable
