import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import { Invoice as InvoiceInterface, useGetInvoicesQuery } from '../generated/graphql'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import theme from '../libs/theme'
type TableData = InvoiceInterface & { index: number }

const useStyles = makeStyles(() => ({
  searchField: {
    borderRadius: '50px !important',
    margin: '20px 0',
  },
}))

const Invoice = () => {
  const classes = useStyles()
  const [data, setData] = useState<TableData[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRow, setSelectedRow] = useState<TableData>({} as TableData)
  const [open, setOpen] = useState(false)
  const { data: invoices, loading } = useGetInvoicesQuery()
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))

  useEffect(() => {
    if (invoices) {
      setData(
        invoices.invoices.map((invoice, index) => ({
          ...invoice,
          index: index + 1,
        })) as TableData[],
      )
    }
  }, [invoices])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleRowClick = (param: any) => {
    setSelectedRow(param)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 20,
      valueGetter: (params: any) => {
        return params.row.index
      },
    },
    { field: 'subject', headerName: 'Predmet', width: 650 },
    { field: 'numberOfInvoice', headerName: 'Číslo faktúry' },
    {
      field: 'publicDate',
      headerName: 'Dátum zverejnenia',
      width: 150,
      valueGetter: (params: any) => {
        return new Date(params.row.publicDate).toLocaleDateString('sk-SK')
      },
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: (params: any) => (
        <Button className={classes.searchField} onClick={() => handleRowClick(params.row)}>
          Detail
        </Button>
      ),
    },
  ]

  const filteredData = data.filter((row) =>
    Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )

  return (
    <>
      <Typography variant='h5' component='div'>
        Faktúry
      </Typography>
      <div>
        <TextField
          id='outlined-basic'
          label={'Hladat'}
          value={searchTerm}
          onChange={handleSearchChange}
          className={classes.searchField}
        />
      </div>
      <DataGrid
        rows={filteredData}
        columns={columns}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullScreen={fullScreen}
      >
        <DialogContent>
          <Typography variant='h5' component='div' align='center'>
            Detail Faktúry
          </Typography>
          <Box mt={3}>
            <Grid container spacing={2}>
              {selectedRow.order ? (
                <Grid item xs={12}>
                  <Typography variant='h5' component='div'>
                    Detail Objednávky
                  </Typography>
                  <div style={{ height: 200, width: '100%' }}>
                    <DataGrid
                      rows={[selectedRow.order]}
                      columns={[
                        { field: 'subject', headerName: 'Predmet', width: 200 },
                        {
                          field: 'price',
                          headerName: 'Cena',
                          valueGetter: (params: any) => {
                            return `${params.row.price} €`
                          },
                        },
                        {
                          field: 'priceWithDPH',
                          headerName: 'DPH',
                          valueGetter: (params: any) => {
                            if (params.row.priceWithDPH) {
                              return 'Platca'
                            } else {
                              return 'Neplatca'
                            }
                          },
                        },
                        {
                          field: 'dateIssue',
                          headerName: 'Dátum vystavenia',
                          width: 100,
                          valueGetter: (params: any) => {
                            return new Date(params.row.dateIssue).toLocaleDateString('sk-SK')
                          },
                        },
                        {
                          field: 'deliveryDate',
                          headerName: 'Dátum dodania',
                          width: 150,
                          valueGetter: (params: any) => {
                            return new Date(params.row.dateIssue).toLocaleDateString('sk-SK')
                          },
                        },
                      ]}
                    />
                  </div>
                </Grid>
              ) : null}
              {selectedRow.contract ? (
                <Grid item xs={12}>
                  <Typography variant='h5' component='div'>
                    Detail Zmluvy
                  </Typography>
                  <div style={{ height: 200, width: '100%' }}>
                    <DataGrid
                      rows={[selectedRow.contract]}
                      columns={[
                        { field: 'subject', headerName: 'Predmet', width: 200 },
                        { field: 'price', headerName: 'Cena' },
                        {
                          field: 'dateIssue',
                          headerName: 'Dátum vystavenia',
                          width: 100,
                          valueGetter: (params: any) => {
                            return new Date(params.row.dateIssue).toLocaleDateString('sk-SK')
                          },
                        },
                        {
                          field: 'deliveryDate',
                          headerName: 'Dátum dodania',
                          width: 150,
                          valueGetter: (params: any) => {
                            return new Date(params.row.dateIssue).toLocaleDateString('sk-SK')
                          },
                        },
                        {
                          field: 'priceWithDPH',
                          headerName: 'DPH',
                          valueGetter: (params: any) => {
                            if (params.row.priceWithDPH) {
                              return 'Platca'
                            } else {
                              return 'Neplatca'
                            }
                          },
                        },
                      ]}
                      pagination
                    />
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Invoice
