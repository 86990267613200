import React, { useState } from 'react'
import { Button, TextField, Switch, FormControlLabel, Autocomplete, Grid, Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useAuth } from '../../context/AuthContext'
import { useGetSuppliersQuery, useCreateContractMutation, Supplier } from '../../generated/graphql'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  textField: {
    width: '100%',
  },
})

const ContractForm: React.FC = () => {
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const { loading, error, data } = useGetSuppliersQuery()
  const [createContract] = useCreateContractMutation()
  const classes = useStyles()

  const [subject, setSubject] = useState('')
  const [dateIssue, setDateIssue] = useState<Date | null>(null)
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null)
  const [price, setPrice] = useState<number | null>(null)
  const [priceWithDph, setPriceWithDPH] = useState(false)
  const [publicDate, setPublicDate] = useState<Date | null>(null)
  const [supplier, setSupplier] = useState<Supplier | null>(null)

  if (loading) return <p>Načítavam...</p>
  if (error) return <p>Nastala chyba :(</p>

  const suppliers = data?.suppliers as Supplier[]

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    if (subject && dateIssue && deliveryDate && price && publicDate && supplier && user) {
      const input = {
        subject,
        dateIssue,
        deliveryDate,
        price,
        priceWithDph,
        publicDate,
        supplier: { id: supplier.id },
        user: { id: user.id },
      }

      const variables = {
        input,
      }

      try {
        const data = await createContract({ variables })

        enqueueSnackbar('Zmluva úspešne vytvorená!', { variant: 'success' })

        if (data) {
          window.location.href = '/contracts'
        }
      } catch (err) {
        enqueueSnackbar('Vytvorenie zmluvy zlyhalo.', { variant: 'error' })
        console.error(err)
      }
    }
  }

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} direction='column'>
          <Grid item>
            <TextField
              id='outlined-multiline-static'
              multiline
              rows={5}
              label='Predmet zmluvy'
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <TextField
              label='Dátum vystavenia'
              type='date'
              value={dateIssue ? dateIssue.toISOString().substring(0, 10) : ''}
              onChange={(event) => setDateIssue(new Date(event.target.value))}
              InputProps={{
                inputProps: {
                  min: new Date().toISOString().substring(0, 10),
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <TextField
              label='Dátum dodania'
              type='date'
              value={deliveryDate ? deliveryDate.toISOString().substring(0, 10) : ''}
              onChange={(event) => setDeliveryDate(new Date(event.target.value))}
              InputProps={{
                inputProps: {
                  min: new Date().toISOString().substring(0, 10),
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <TextField
              label='Cena'
              type='number'
              value={price || ''}
              onChange={(event) => setPrice(parseFloat(event.target.value))}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={priceWithDph}
                  onChange={(event) => setPriceWithDPH(event.target.checked)}
                  name='priceWithDph'
                />
              }
              label='Cena s DPH'
            />
          </Grid>
          <Grid item>
            <TextField
              label='Dátum verejnosti'
              type='date'
              value={publicDate ? publicDate.toISOString().substring(0, 10) : ''}
              onChange={(event) => setPublicDate(new Date(event.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              options={suppliers}
              getOptionLabel={(option) => option.name || ''}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label='Dodávateľ' />}
              onChange={(event, newValue) => {
                setSupplier(newValue)
              }}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <Button type='submit' variant='contained'>
              Odoslať
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default ContractForm
