import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import { useGetOrdersQuery, Order as OrderInterface, Invoice } from '../generated/graphql'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import InvoiceTable from '../components/Table/InvoiceTable'
import theme from '../libs/theme'

type TableData = OrderInterface & { index: number }

const useStyles = makeStyles(() => ({
  searchField: {
    borderRadius: '50px !important',
    margin: '20px 0',
  },
  wrapCell: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
}))

const Order = () => {
  const classes = useStyles()
  const [data, setData] = useState<TableData[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRow, setSelectedRow] = useState<TableData>({} as TableData)
  const [open, setOpen] = useState(false)
  const { data: orders, loading } = useGetOrdersQuery()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (orders) {
      setData(orders.orders.map((order, index) => ({ ...order, index: index + 1 })) as TableData[])
    }
  }, [orders])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleRowClick = (param: any) => {
    setSelectedRow(param)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 20,
      valueGetter: (params: any) => {
        return params.row.index
      },
    },
    {
      field: 'subject',
      headerName: 'Predmet',
      width: 650,
      renderCell: (params: any) => (
        <div
          style={{
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            height: '100%',
            width: '100%',
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'price',
      headerName: 'Cena',
      valueGetter: (params: any) => {
        return params.row.price + ' €'
      },
    },
    {
      field: 'priceWithDPH',
      headerName: 'DPH',
      valueGetter: (params: any) => {
        if (params.row.priceWithDPH) {
          return 'Platca'
        } else {
          return 'Neplatca'
        }
      },
    },
    {
      field: 'supplier',
      headerName: 'Názov dodávateľa',
      width: 250,
      valueGetter: (params: any) => {
        return params.row.supplier.name
      },
    },
    {
      field: 'supplier.ico',
      headerName: 'IČO',
      width: 100,
      valueGetter: (params: any) => {
        return params.row.supplier.ico
      },
    },
    {
      field: 'dateIssue',
      headerName: 'Dátum vystavenia',
      width: 150,
      valueGetter: (params: any) => {
        return new Date(params.row.dateIssue).toLocaleDateString('sk-SK')
      },
    },
    {
      field: 'deliveryDate',
      headerName: 'Dátum dodania',
      width: 150,
      valueGetter: (params: any) => {
        return new Date(params.row.dateIssue).toLocaleDateString('sk-SK')
      },
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: (params: any) => (
        <Button className={classes.searchField} onClick={() => handleRowClick(params.row)}>
          Detail
        </Button>
      ),
    },
  ]

  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )

  return (
    <>
      <Typography variant='h5' component='div'>
        Objednávky
      </Typography>
      <div>
        <TextField
          id='outlined-basic'
          label={'Hladat'}
          value={searchTerm}
          onChange={handleSearchChange}
          className={classes.searchField}
        />
      </div>
      <DataGrid
        rows={filteredData}
        columns={columns}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        isRowSelectable={(params) => false}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullScreen={fullScreen}
      >
        <DialogContent>
          <Typography variant='h5' component='div' align='center'>
            Detail Objednávky
          </Typography>
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography variant='h6' mb={2}>
                  Informácie o objednávke
                </Typography>
                <Box mb={2}>
                  <TextField
                    label='Predmet'
                    value={selectedRow.subject}
                    disabled
                    fullWidth
                    multiline
                    rows={5}
                  />
                </Box>
                <Box mb={2}>
                  <TextField label='Cena' value={selectedRow.price + ' €'} disabled fullWidth />
                </Box>
                <TextField
                  label='Vystavil'
                  value={selectedRow.user?.firstName + ' ' + selectedRow.user?.lastName}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h6' mb={2}>
                  &nbsp;
                </Typography>
                <Box mb={2}>
                  <TextField
                    label='Dátum vystavenia'
                    value={new Date(selectedRow.dateIssue).toLocaleDateString('sk-SK')}
                    disabled
                    fullWidth
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label='Dátum dodania'
                    value={new Date(selectedRow.deliveryDate).toLocaleDateString('sk-SK')}
                    disabled
                    fullWidth
                  />
                </Box>
                <TextField
                  label='Platca DPH'
                  value={selectedRow.priceWithDPH ? 'Platca' : 'Neplatca'}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                {selectedRow.invoices && selectedRow.invoices.length > 0 ? (
                  <InvoiceTable invoices={selectedRow.invoices} loading={loading} />
                ) : (
                  <Typography variant='subtitle1' color='textSecondary'>
                    Nie sú dostupné žiadne faktúry.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Order
