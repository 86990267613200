import OrderForm from '../../components/Order/OrderForm'

const OrdersDash = () => {
  return (
    <div>
      <OrderForm />
    </div>
  )
}

export default OrdersDash
