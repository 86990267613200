import React from 'react'
import { AppBar, Toolbar, Typography, IconButton, Button, Menu, MenuItem } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import { Link } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'

export const Navbar: React.FC = () => {
  const { user, logout } = useAuth()
  const [anchorEl, setAnchorEl] = React.useState<{ [key: string]: null | HTMLElement }>({
    orders: null,
    contracts: null,
    invoices: null,
    suppliers: null,
  })

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, type: string) => {
    setAnchorEl({ ...anchorEl, [type]: event.currentTarget })
  }

  const handleClose = (type: string) => {
    setAnchorEl({ ...anchorEl, [type]: null })
  }

  return (
    <AppBar position='static'>
      <Toolbar>
        <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
          Povinné zverejnovanie
        </Typography>
        {user ? (
          <>
            <Button
              variant='contained'
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={(event) => handleClick(event, 'orders')}
            >
              Objednávky
            </Button>
            <Menu
              id='simple-menu'
              anchorEl={anchorEl.orders}
              keepMounted
              open={Boolean(anchorEl.orders)}
              onClose={() => handleClose('orders')}
            >
              <MenuItem onClick={() => handleClose('orders')}>
                <Link to='/dash/orders'>Pridať Objednávku</Link>
              </MenuItem>
              <MenuItem onClick={() => handleClose('orders')}>
                <Link to='/orders'>Objednávky</Link>
              </MenuItem>
            </Menu>

            <Button
              variant='contained'
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={(event) => handleClick(event, 'contracts')}
            >
              Zmluvy
            </Button>
            <Menu
              id='simple-menu'
              anchorEl={anchorEl.contracts}
              keepMounted
              open={Boolean(anchorEl.contracts)}
              onClose={() => handleClose('contracts')}
            >
              <MenuItem onClick={() => handleClose('contracts')}>
                <Link to='/dash/contracts'>Pridať Zmluvu</Link>
              </MenuItem>
              <MenuItem onClick={() => handleClose('contracts')}>
                <Link to='/contracts'>Zmluvy</Link>
              </MenuItem>
            </Menu>

            <Button
              variant='contained'
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={(event) => handleClick(event, 'invoices')}
            >
              Faktúry
            </Button>
            <Menu
              id='simple-menu'
              anchorEl={anchorEl.invoices}
              keepMounted
              open={Boolean(anchorEl.invoices)}
              onClose={() => handleClose('invoices')}
            >
              <MenuItem onClick={() => handleClose('invoices')}>
                <Link to='/dash/invoices'>Pridať Faktúru</Link>
              </MenuItem>
              <MenuItem onClick={() => handleClose('invoices')}>
                <Link to='/invoices'>Faktúry</Link>
              </MenuItem>
            </Menu>

            <Button
              variant='contained'
              aria-controls='simple-menu'
              aria-haspopup='true'
              onClick={(event) => handleClick(event, 'suppliers')}
            >
              Dodavateľ
            </Button>
            <Menu
              id='simple-menu'
              anchorEl={anchorEl.suppliers}
              keepMounted
              open={Boolean(anchorEl.suppliers)}
              onClose={() => handleClose('suppliers')}
            >
              <MenuItem onClick={() => handleClose('suppliers')}>
                <Link to='/dash/suppliers'>Pridať Dodavatela</Link>
              </MenuItem>
            </Menu>

            <Link to='/'>
              <IconButton color='default' aria-label='home'>
                <HomeIcon />
              </IconButton>
            </Link>
            <Button color='inherit' onClick={logout}>
              Odhlásiť
            </Button>
          </>
        ) : (
          <>
            <Link to='/orders'>
              <Button variant='contained'>Objednávky</Button>
            </Link>
            <Link to='/contracts'>
              <Button variant='contained'>Zmluvy</Button>
            </Link>
            <Link to='/invoices'>
              <Button variant='contained'>Faktúry</Button>
            </Link>
            <Link to='/'>
              <IconButton color='default' aria-label='home'>
                <HomeIcon />
              </IconButton>
            </Link>
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}
