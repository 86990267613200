import React, { useState } from 'react'
import { Button, TextField, Autocomplete, Grid, Box, FormControlLabel, Switch } from '@mui/material'
import { useSnackbar } from 'notistack'
import {
  useGetContractsQuery,
  useGetOrdersQuery,
  useCreateInvoiceMutation,
  Contract,
  Order,
} from '../../generated/graphql'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  textField: {
    width: '100%',
  },
})

const InvoiceForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    loading: contractsLoading,
    error: contractsError,
    data: contractsData,
  } = useGetContractsQuery()
  const { loading: ordersLoading, error: ordersError, data: ordersData } = useGetOrdersQuery()
  const [createInvoice] = useCreateInvoiceMutation()
  const classes = useStyles()

  const [subject, setSubject] = useState('')
  const [publicDate, setPublicDate] = useState<Date | null>(null)
  const [contract, setContract] = useState<Contract | null>(null)
  const [order, setOrder] = useState<Order | null>(null)
  const [numberOfInvoice, setNumberOfInvoice] = useState<string | null>(null)
  const [dof, setDof] = useState(false)

  if (contractsLoading || ordersLoading) return <p>Načítavam...</p>
  if (contractsError || ordersError) return <p>Nastala chyba :(</p>

  const contracts = contractsData?.contracts as Contract[]
  const orders = ordersData?.orders as Order[]

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    if (subject && publicDate && contract && order && numberOfInvoice) {
      const input = {
        subject,
        publicDate,
        contract: { id: contract.id },
        order: { id: order.id },
        numberOfInvoice,
        dodavatelska: dof,
      }

      const variables = {
        input,
      }

      try {
        const data = await createInvoice({ variables })

        enqueueSnackbar('Faktura úspešne vytvorená!', { variant: 'success' })

        if (data) {
          window.location.href = '/invoices'
        }
      } catch (err) {
        enqueueSnackbar('Vytvorenie faktury zlyhalo.', { variant: 'error' })
        console.error(err)
      }
    }
  }

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} direction='column'>
          <Grid item>
            <TextField
              id='outlined-multiline-static'
              multiline
              rows={5}
              label='Predmet faktúry'
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <TextField
              label='Dátum zverejnenia'
              type='date'
              value={
                publicDate
                  ? publicDate.toISOString().substring(0, 10)
                  : new Date().toISOString().substring(0, 10)
              }
              onChange={(event) => setPublicDate(new Date(event.target.value))}
              InputProps={{
                inputProps: {
                  min: new Date().toISOString().substring(0, 10),
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              options={contracts}
              getOptionLabel={(option) => `${option.numberOfContract} - ${option.supplier?.name}`}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label='Zmluva' />}
              onChange={(event, newValue) => {
                setContract(newValue)
              }}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              options={orders}
              getOptionLabel={(option) => `${option.numberOfOrder} - ${option.supplier?.name}`}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label='Objednávka' />}
              onChange={(event, newValue) => {
                setOrder(newValue)
              }}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={dof}
                  onChange={(event) => setDof(event.target.checked)}
                  name='DOF'
                />
              }
              label='DOF'
            />
          </Grid>
          <Grid item>
            <TextField
              label='Číslo faktury'
              type='text'
              value={numberOfInvoice || ''}
              onChange={(event) => setNumberOfInvoice(event.target.value)}
              className={classes.textField}
            />
          </Grid>
          <Grid item>
            <Button type='submit' variant='contained'>
              Pridať
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default InvoiceForm
