import React, { FormEvent, useState, ReactNode } from 'react'
import { Box, Button, Container, TextField, Stack } from '@mui/material'
import ButtonBox from '../../components/ButtonBox'
import { useAuth } from '../../context/AuthContext'
import Home from '../Home'

type DashProps = {
  children?: ReactNode
}

const Dash: React.FC<DashProps> = ({ children }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const { user, login } = useAuth()

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault()
    await login(username, password)
  }

  return (
    <>
      {!user ? (
        <Container maxWidth='sm'>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            minHeight='100vh'
          >
            <Stack component='form' onSubmit={handleLogin} spacing={2} width='100%'>
              <TextField
                label='Username'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
              />
              <TextField
                label='Password'
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
              <Button variant='contained' color='primary' type='submit' fullWidth>
                Login
              </Button>
            </Stack>
          </Box>
        </Container>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default Dash
