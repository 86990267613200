import ContractForm from '../../components/Contract/ContractForm'

const ContractDash = () => {
  return (
    <div>
      <ContractForm />
    </div>
  )
}

export default ContractDash
